<template>
  <LxpMobileHeader title="코칭 전체보기" />
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-talentboard-coaching">
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="coaching-container">
        <div class="content-section">
          <div class="feed-list">
            <!-- feed -->
            <div class="feed">
              <div class="feed-avatar">
                <ProfileImg
                    :target="{lrnerId: hope.mdfrId}"
                />
              </div>
              <div class="feed-content">
                <p class="feed-text">{{hope.goalsCn}}</p>
                <div class="feed-meta"><strong class="sender">{{ hope.mdfrNm }}(나)</strong><span class="date">{{timestampToDateFormat(hope.mdfcnDt, 'yyyy.MM.dd hh:mm')}}</span></div>
              </div>
            </div>
            <!-- feed -->
            <div v-for="(coaching, index) in coachings" class="feed coaching" :key="index">
              <div class="feed-avatar">
                <ProfileImg
                    :target="{lrnerId: coaching.mdfrId}"
                />
              </div>
              <div class="feed-content">
                <p class="feed-text">{{coaching.goalsCn}}</p>
                <div class="feed-meta"><strong class="sender">{{ coaching.mdfrNm }}({{coaching.mdfrId}})</strong><span class="date">{{ timestampToDateFormat(hope.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {timestampToDateFormat} from '@/assets/js/util';
import {computed, ref} from 'vue';
import {getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_LRN_CRSE_WORK_GOAL_LIST, ACT_GET_LRN_CRSE_WORK_PHASE_LIST} from '@/store/modules/talent/talent';
import {crseWorkTyCdDcds} from '@/assets/js/modules/mypage/talent-common';
import ProfileImg from '@/components/common/ProfileImg';

export default {
  name: 'MobileMyTalentBoardCoaching',
  components: {ProfileImg, LxpMobileHeader},
  setup() {
// Talent 항목들
    const works = ref([]);
    // 현재 내 설정
    const goals = ref([]);
    const crseWorkDtlSns = computed(() => works.value.map(x => x.crseWorkDtlSn));

    const hope = computed(() => {
      const hope = goals.value.find(x => crseWorkDtlSns.value.includes(x.crseWorkDtlSn) && x.goalsDivCd === '01');
      return hope ? hope : {
        crseWorkDtlSn: 0
      };
    });

    const coachings = computed(() => {
      return goals.value.filter(x => x.goalsDivCd === '02');
    });

    const getTalentWorks = () => {
      getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_GOAL_LIST}`, null, goals, null, () => {});
    }
    getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_PHASE_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.ability}, works, null, () => {getTalentWorks();});


    return {
      hope,
      coachings,
      timestampToDateFormat
    }


  }
};
</script>
